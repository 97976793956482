.grid
    display: flex

h1,
.h1
    line-height: 1.1em
    font-weight: 700
    font-size: 35px
    @media only screen and (min-width: 32rem)
        font-size: 50px

h2,
.h2
    line-height: 1.2em
    font-weight: 700
    font-size: 28px
    
    @media only screen and (min-width: 32rem)
        font-size: 35px


h3,
.h3
    line-height: 1.1em
    font-size: 28px
    font-weight: 700

p
    font-size: 18px
    line-height: 1.2em
    font-weight: 400

    @media only screen and (min-width: 32rem)
        font-size: 20px
    

strong
    font-weight: 700

.break
    hyphens: auto


.hidden
    visibility: hidden

.span-block
    display: block

.auto
    display: inline-block
    width: auto

.sr-only
    position: absolute
    clip-path: rect(1px,1px,1px,1px)
    -webkit-clip-path: inset(50%)
    width: 1px
    height: 1px
    overflow: hidden
    padding: 0
    border: 0
    white-space: nowrap
    
.icon
    display: block
    color: inherit
    fill: currentColor
    height: 1em
    width: 1em
    line-height: 1
    flex-shrink: 0
    max-width: initial

.bg-pink
    background-color: var(--color-pink)

.bg-green
    background-color: var(--color-green)

.bg-green-dark
    background-color: var(--color-green-dark)

.bg-purple
    background-color: var(--color-purple)

.bg-purple-light
    background-color: var(--color-purple-light)

.bg-blue
    background-color: var(--color-blue)


.btn
    display: inline-flex
    padding: 16px 30px
    border-radius: 50px
    color: #fff
    font-size: 18px
    line-height: 1em
    text-decoration: none
    border-width: 5px
    border-style: solid
    transition: all 0.2s
    text-align: center
    font-family: "Kohinoor"
    
    @media only screen and (min-width: 32rem)
        font-size: 20px
    &.btn--green
        background-color: var(--color-green)
        border-color: var(--color-green)

    &.btn--pink
        background-color: var(--color-pink)
        border-color: var(--color-pink)

    &.btn--purple
        background-color: var(--color-purple)
        border-color: var(--color-purple)
    
    &.btn--purple-light
        background-color: var(--color-purple-light)
        border-color: var(--color-purple-light)

    &:hover
        text-decoration: none
        background-color: #fff

        &.btn--green
            color: var(--color-green)

        &.btn--pink
            color: var(--color-pink)

        &.btn--purple
            color: var(--color-purple)
        &.btn--purple-light
            color: var(--color-purple-light)

.m5
    margin-bottom: 50px
.m10
    margin-bottom: 100px

.page-content
    max-width: var(--max-width-xxxl)
    margin-left: auto
    margin-right: auto