.modal
    position: fixed
    z-index: 1000
    width: 100%
    height: 100vh
    left: 0
    bottom: 0
    display: flex
    justify-content: center
    align-items: center
    opacity: 0
    visibility: hidden
    
    &:not(.modal--is-visible)
        pointer-events: none
        background-color: transparent

    &.modal--is-visible
        opacity: 1
        visibility: visible
        

    &.modal--warning
        background-color: var(--color-pink)
        z-index: 1002
        top: 0
        bottom: auto

    &.modal--password
        background-color: #fff
        z-index: 1002
        top: 0
        bottom: auto

    &.modal--video
        background-color: #fff

    &__form
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center

        p
            padding-top: 10px
            color: var(--color-purple-light)

        input
            padding: 10px 30px
            border: 5px solid var(--color-purple-light)
            font-size: 20px
            line-height: 1em
            color: var(--color-purple-light)
            border-radius: 50px
            margin-bottom: 20px
        
    &__content
        position: relative
        width: 100%
        overflow: auto
        height: 100%
        @media only screen and (min-width: 48rem)
            height: auto

    &__content--imprint
        background-color: var(--color-turquoise)
        max-width: var(--max-width-xs)
        padding: var(--space-xl) var(--space-lg) var(--space-lg) var(--space-lg)
        color: #fff
        text-align: left
        font-size: 20px
        font-weight: 400
        @media only screen and (min-width: 48rem)
            padding: var(--space-lg) var(--space-lg)

        a
            color: #fff

    &__content--warning
        width: calc( 100% - var(--space-md) )
        padding: 70px 0
        max-width: var(--max-width-lg)
        margin-left: auto
        margin-right: auto
        font-size: 25px
        text-align: center
        color: #fff       

        a
            color: #fff

        @media only screen and (min-width: 64rem)
            font-size: 40px

    &__content--video
        position: relative
        width: 100%
        height: 100vh

        .video-figure-modal
            position: relative
            width: 100%
            height: 100vh
            z-index: 14

            video,
            img
                position: absolute
                z-index: 15
                top: 0
                left: 0
                width: 100%
                height: 100%
                object-fit: contain
                display: block
                transition: opacity 0.2s

                @media only screen and (min-width: 64rem)
                    object-fit: cover

    &__video-unmute
        position: absolute
        z-index: 15
        left: var(--space-lg)
        bottom: 60px
        @media only screen and (min-width: 64rem)
            bottom: var(--space-lg)

        svg
            position: relative
            width: 75px
            height: 68px

    &__close-btn--inner
        position: absolute
        color: #fff
        top: 0
        right: 0
        cursor: pointer
        display: flex
        align-items: center
        justify-content: center
        transition: 0.2s
        z-index: 15
        width: 50px
        height: 50px
        background-color: var(--color-blue)
        
        &:hover,
        &:focus,
        &:focus-visible
            background-color: var(--color-brown)
            
        .icon
            font-size: 27px

    &__close-btn-warning
        color: var(--color-blue)
        background-color: #fff
        &:hover,
        &:focus,
        &:focus-visible
            color: #fff
            background-color: var(--color-blue)


    &__close-btn-video
        position: absolute
        z-index: 15
        right: var(--space-lg)
        bottom: 60px
        @media only screen and (min-width: 64rem)
            bottom: var(--space-lg)

.modal--animate-fade,
.modal--animate-scale,
.modal--animate-translate-up,
.modal--animate-translate-down,
.modal--animate-translate-right,
.modal--animate-translate-left,
.modal--animate-slide-up,
.modal--animate-slide-down,
.modal--animate-slide-right,
.modal--animate-slide-left
    transition: opacity var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s var(--modal-transition-duration)
    
    &.modal--is-visible
        transition: opacity var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s

        .modal__content
            -webkit-transform: scale(1)
            transform: scale(1)

.modal--animate-scale .modal__content,
.modal--animate-translate-up .modal__content,
.modal--animate-translate-down .modal__content,
.modal--animate-translate-right .modal__content,
.modal--animate-translate-left .modal__content,
.modal--animate-slide-up .modal__content,
.modal--animate-slide-down .modal__content,
.modal--animate-slide-right .modal__content,
.modal--animate-slide-left .modal__content,
.modal--animate-slide-up.modal--is-visible .modal__content,
.modal--animate-slide-down.modal--is-visible .modal__content,
.modal--animate-slide-right.modal--is-visible .modal__content,
.modal--animate-slide-left.modal--is-visible .modal__content
    will-change: transform
    transition: -webkit-transform var(--modal-transition-duration) var(--ease-out)
    transition: transform var(--modal-transition-duration) var(--ease-out)
    transition: transform var(--modal-transition-duration) var(--ease-out), -webkit-transform var(--modal-transition-duration) var(--ease-out)

.modal--animate-scale .modal__content
    -webkit-transform: scale(0.95)
    transform: scale(0.95)
                
.modal--animate-translate-up .modal__content
    -webkit-transform: translateY(40px)
    transform: translateY(40px)

.modal--animate-translate-down .modal__content
    -webkit-transform: translateY(-40px)
    transform: translateY(-40px)

.modal--animate-translate-left .modal__content
    -webkit-transform: translateX(-40px)
    transform: translateX(-40px)

.modal--animate-slide-up .modal__content
    -webkit-transform: translateY(100%)
    transform: translateY(100%)

.modal--animate-slide-down .modal__content
    -webkit-transform: translateY(-100%)
    transform: translateY(-100%)

.modal--animate-slide-right .modal__content
    -webkit-transform: translateX(-100%)
    transform: translateX(-100%)

.modal--animate-slide-left .modal__content
    -webkit-transform: translateX(100%)
    transform: translateX(100%)

.modal--is-loading .modal__content
    visibility: hidden

.modal--is-loading .modal__loader
    display: flex


@keyframes icon-spin
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)


.modal__loader
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    justify-content: center
    align-items: center
    display: none
    pointer-events: none
    
    .icon
        font-size: 48px
        color: var(--color-blue)

        &.icon--is-spinning
            animation: icon-spin 1s infinite linear


.imprint-trigger
    color: #000
    font-size: 20px