.akten-archive,
.ddr,
.familie,
.kirche,
.mmb,
.org,
.pn,
.schule,
.sport,
.zj,
.weiterethemen
    overflow: hidden
    
    .color-accent
        color: var(--color-purple)

.topics

    &__intro
        position: relative
        &.pn--margin
            @media only screen and (min-width: 64rem)
                margin-bottom: 250px

    &__intro-vector
        position: relative
        width: 100%
        height: 100%
        display: block
        text-align: center
        margin-left: auto
        margin-right: auto
        margin-bottom: 30px

        @media only screen and (min-width: 64rem)
            position: absolute
            margin-bottom: auto

        &#aktenarchive
            height: 120px
            width: auto
            @media only screen and (min-width: 64rem)
                transform: translate(calc(-100% - 20px), -20%)
                top: 0
                left: 0
                height: 250px

        &#ddr
            height: 120px
            width: auto
            @media only screen and (min-width: 64rem)
                transform: translate(calc(-100% - 20px), -20%)
                top: 0
                left: 0
                height: 250px

        &#familie
            height: 120px
            width: auto
            @media only screen and (min-width: 64rem)
                top: 0
                left: 0
                height: 250px
                transform: translate(calc(-100% - 20px), -20%)


        &#kirche
            height: 120px
            width: auto
            @media only screen and (min-width: 64rem)
                top: 0
                left: 0
                height: 250px
                transform: translate(calc(-100% - 20px), -25%)


        &#wheelchair
            max-height: 120px
            @media only screen and (min-width: 64rem)
                top: 0
                left: 0
                max-width: 206px
                max-height: 175px
                transform: translate(calc(-100% - 20px), 0)


        &#org
            height: 120px
            width: auto
            @media only screen and (min-width: 64rem)
                top: 0
                left: 0
                height: 250px
                transform: translate(calc(-100% - 20px), -15%)

        &#pn
            height: 120px
            width: auto
            @media only screen and (min-width: 64rem)
                bottom: 0
                left: 0
                height: 250px
                transform: translate(calc(-100% - 20px), 20%)

        &#pn2
            max-height: 120px
            display: none
            @media only screen and (min-width: 64rem)
                display: block
                top: 0
                right: 0
                max-width: 128px
                max-height: 131px
                transform: translate(calc(100% + 20px), 0)

        &#schule
            height: 120px
            @media only screen and (min-width: 64rem)
                top: 0
                left: 0
                width: auto
                height: 250px
                transform: translate(calc(-100% - 50px), -20%)

        &#sport
            height: 120px
            width: auto
            @media only screen and (min-width: 64rem)
                top: 0
                left: 0
                height: 250px
                transform: translate(-110%, 0%)

        &#sport2
            max-height: 120px
            display: none
            @media only screen and (min-width: 64rem)
                display: block
                top: 0
                right: 0
                max-width: 82px
                max-height: 81px
                transform: translate(calc(100% + 20px), -100%)

        &#zj
            height: 120px
            width: auto
            @media only screen and (min-width: 64rem)
                top: 0
                left: 0
                height: 250px
                transform: translate(calc(-100% - 20px), 0)

        &#markerA
            display: none
            @media only screen and (min-width: 64rem)
                display: block
                top: 0
                left: 0
                max-width: 220px
                max-height: 207px
                transform: translate(calc(-100% - 20px), 25%)

        &#markerB
            display: none
            @media only screen and (min-width: 64rem)
                display: block
                top: 0
                right: 0
                max-width: 177px
                max-height: 140px
                transform: translate(100%, -50%)

    &__container,
    &__container--small,
    &__container--medium
        .h1, 
        .h2

            margin-bottom: 30px
            @media only screen and (min-width: 32rem)
                text-align: center

            &.left
                text-align: left


    &__container--small,
    &__container--medium
        margin-left: auto
        margin-right: auto
    
    &__container--small
        max-width: var(--max-width-xs)
        margin-bottom: 30px

    &__container--medium
        max-width: var(--max-width-md)
        margin-bottom: 50px

    &__grid
        flex-wrap: wrap
        align-items: center
        margin-bottom: 50px

        &.grid--small-width
            max-width: var(--max-width-md)
            margin-left: auto
            margin-right: auto

        &.align-top
            align-items: flex-start
        
        @media only screen and (min-width: 32rem)
            margin-bottom: 100px
            flex-wrap: nowrap
            margin-left: -12.5px
            margin-right: -12.5px
        @media only screen and (min-width: 64rem)
            margin-left: -25px
            margin-right: -25px

    &__col,
    &__col-big,
    &__col-small
        position: relative
        flex-basis: 100%
        margin-bottom: 50px

    &__col

        @media only screen and (min-width: 32rem)
            margin-bottom: 50px
            margin: 0 12.5px
            flex-basis: calc( 50% - 25px )

        @media only screen and (min-width: 64rem)
            margin: 0 25px
            flex-basis: calc( 50% - 50px )            

        &.order-reverse-a
            order: 2

            @media only screen and (min-width: 32rem)
                order: 1

        &.order-reverse-b
            margin-bottom: 30px
            order: 1

            @media only screen and (min-width: 32rem)
                order: 2
                margin-bottom: 0px


        &.padding-top
            @media only screen and (min-width: 32rem)
                padding-top: 150px


        &.col--wt-a
            // border: 1px dashed red
            margin-bottom: 100px
            @media only screen and (min-width: 32rem)
                margin-bottom: 0

        h1
            margin-bottom: 20px
            @media only screen and (min-width: 32rem)
                margin-bottom: 30px
        h2
            margin-bottom: 20px
            @media only screen and (min-width: 32rem)
                margin-bottom: 30px

        .h3
            margin-bottom: 20px


        #birds
            max-width: 523px
            max-height: 390px

        .btn-center
            text-align: center
            padding-top: 30px

    &__col-big,
    &__col-small
        margin-bottom: 50px
        h1
            margin-bottom: 50px
        h2
            margin-bottom: 20px
            @media only screen and (min-width: 32rem)
                margin-bottom: 30px

    &__col-big
        order: 2
        @media only screen and (min-width: 32rem)            
            margin: 0 12.5px
            flex-basis: calc( 60% - 25px )
            order: 1

        @media only screen and (min-width: 64rem)
            margin: 0 25px
            flex-basis: calc( 60% - 50px )       
    
    &__col-small
        order: 1
        @media only screen and (min-width: 32rem)            
            margin: 0 12.5px
            order: 2
            flex-basis: calc( 40% - 25px )

        @media only screen and (min-width: 64rem)
            margin: 0 25px
            flex-basis: calc( 40% - 50px )   

    &__col-center
        display: flex
        justify-content: center
        align-items: center

    &__col-image
        display: flex
        justify-content: center
        align-items: center
            
        #aktenarchive-img-a,
        #sport-img-cover
            max-width: 400px
        
        #familie-img-a
            max-width: 350px

        #org-img-cover,
        #pn-img-cover
            max-width: 400px

        #cover-e,
        #cover-f
            max-width: 346px
            max-height: 475px

    &__col-headline-num
        display: flex

        h2
            margin-bottom: 20px
            @media only screen and (min-width: 32rem)
                margin-bottom: 30px
        
    
    &__col-count
        position: relative        
        &.num--1
            margin-right: 5px
            @media only screen and (min-width: 64rem)
                margin-left: -40px
                margin-right: 0

        &.num--2
            margin-right: 5px
            @media only screen and (min-width: 64rem)
                margin-left: -45px
                margin-right: 0

        #num-one
            width: 30px
            height: 49px
            @media only screen and (min-width: 32rem)
                width: 40px
                height: 65px

        #num-two
            width: 30px
            height: 43px
            @media only screen and (min-width: 32rem)
                width: 45px
                height: 65px
    
    &__face
        display: flex
        justify-content: center
        align-items: center
        #rahel
            width: calc( 324px * 0.6 ) 
            height: calc( 416px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 324px
                height: 416px
        #nadine
            width: calc( 412px * 0.6 ) 
            height: calc( 356px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 412px
                height: 356px

        #monika
            width: calc( 340px * 0.6 ) 
            height: calc( 353px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 340px
                height: 353px
        #juergen
            width: calc( 402px * 0.6 ) 
            height: calc( 336px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 402px
                height: 336px
        #nadine2
            width: calc( 402px * 0.6 ) 
            height: calc( 360px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 402px
                height: 360px
        #katrin
            width: calc( 385px * 0.6 ) 
            height: calc( 353px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 385px
                height: 353px
        #johanna
            width: calc( 385px * 0.6 ) 
            height: calc( 352px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 385px
                height: 352px
        #lutz
            width: calc( 402px * 0.6 ) 
            height: calc( 336px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 402px
                height: 336px
        #no-name-a
            width: calc( 380px * 0.6 ) 
            height: calc( 357px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 380px
                height: 357px
        #valeria
            width: calc( 339px * 0.6 ) 
            height: calc( 355px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 339px
                height: 355px
        #ella
            width: calc( 404px * 0.6 ) 
            height: calc( 330px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 404px
                height: 330px
        #susan
            width: calc( 399px * 0.6 ) 
            height: calc( 358px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 399px
                height: 358px
        #eva
            width: calc( 333px * 0.6 ) 
            height: calc( 359px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 333px
                height: 359px
        #kevin
            width: calc( 445px * 0.6 ) 
            height: calc( 350px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 445px
                height: 350px
        #ingo
            width: calc( 380px * 0.6 ) 
            height: calc( 357px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 380px
                height: 357px
        #anna
            width: calc( 339px * 0.6 ) 
            height: calc( 355px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 339px
                height: 355px
        #bjoern
            width: calc( 390px * 0.6 ) 
            height: calc( 368px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 390px
                height: 368px
        #sophie
            width: calc( 325px * 0.6 ) 
            height: calc( 420px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 325px
                height: 420px
        #horst
            width: calc( 426px * 0.6 ) 
            height: calc( 356px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 426px
                height: 356px
        #daniel
            width: calc( 362px * 0.6 ) 
            height: calc( 341px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 362px
                height: 341px
        #mia
            width: calc( 335px * 0.6 ) 
            height: calc( 361px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 335px
                height: 361px
        #katrin2
            width: calc( 340px * 0.6 ) 
            height: calc( 353px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 340px
                height: 353px
        #boris
            width: calc( 380px * 0.6 ) 
            height: calc( 357px * 0.6 ) 
            @media only screen and (min-width: 32rem)
                width: 380px
                height: 357px

    &__vector
        display: flex
        justify-content: center
        align-items: center

        &.aline--end
            justify-content: flex-end
            align-items: flex-start

        svg
            width: 100%
            height: 100%

        #vector-ddr
            max-width: 606px
            max-height: 363px
        #vector-familie
            max-width: 606px
            max-height: 363px
        #vector-kirche-a
            max-width: 605px
            max-height: 365px
        #leichte-sprache
            max-width: 216px
            max-height: 260px
        #sprechblasen
            max-width: 489px
            max-height: 360px
        #vector-org
            max-width: 410px
            max-height: 457px
        #daumen
            max-width: 284px
            max-height: 328px
        #zahlen-org,
        #zahlen-schule,
        #zahlen-sport
            max-width: 606px
            max-height: 363px
        #rucksack-schule
            max-width: 336px
            max-height: 327px
        #studie-schule
            max-width: 346px
            max-height: 475px
        #group-zj
            width: 469px
            height: 268px
        #rucksack-work
            max-width: 401px
            max-height: 390px
        #berichte
            max-width: 626px
            max-height: 279px
        #leichte-sprache
            max-width: 251px
            max-height: 252px
        #gebaerdensprache
            max-width: 235px
            max-height: 284px
        #cover-a,
        #cover-b,
        #cover-c,
        #cover-d,
        #cover-g,
        #cover-h
            max-width: 346px
            max-height: 475px

    &__sm
        margin-bottom: 50px
        @media only screen and (min-width: 32rem)
            margin-bottom: 50px

        h1
            margin-bottom: 50px
            @media only screen and (min-width: 32rem)
                margin-bottom: 50px
                text-align: center
    
    &__sm-grid
        position: relative
        width: 80%
        margin-left: auto
        margin-right: auto
        flex-wrap: wrap
        @media only screen and (min-width: 32rem)
            width: 100%

    &__sm-col
        flex-basis: 100%
        display: flex
        justify-content: center
        align-items: center
        @media only screen and (min-width: 32rem)
            flex-basis: 33.3333%

    &__sm-box
        max-width: 350px
        width: 100%

    &__textblock
        margin-left: auto
        margin-right: auto
        max-width: var(--max-width-sm)
        margin-bottom: 50px

        h2
            margin-bottom: 30px
        
    &__video-mobile
        max-width: 70%
        margin-left: auto
        margin-right: auto
        margin-bottom: 30px

    &__fullwidth-video
        margin-bottom: 50px

    &__btns
        display: flex
        flex-direction: column
        @media only screen and (min-width: 32rem)
            flex-direction: row
        .auto
            margin-bottom: 20px
            @media only screen and (min-width: 32rem)
                margin-right: 20px
                margin-bottom: 0

.bird-a,
.bird-b,
.bird-c
    position: absolute

.bird-a    
    top: 0
    left: 20%
    transform: translate(0, -25%)

    svg
        width: 104px
        height: 150px
        @media only screen and (min-width: 64rem)
            width: 151px
            height: 215px

.bird-b
    position: absolute
    bottom: 0
    left: 0
    svg
        width: 96px
        height: 90px
        @media only screen and (min-width: 64rem)
            width: 110px
            height: 141px
.bird-c
    position: absolute
    bottom: 20%
    right: 20%
    svg
        width: 65px
        height: 90px
        @media only screen and (min-width: 64rem)
            width: 110px
            height: 141px