.loader::before,
.loader::after
    content: ''
    position: fixed
    z-index: 9999
    cursor: none

.loader::before
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: #fff

.loader::after
    top: 50%
    left: 50%
    width: 100px
    height: 100px
    pointer-events: none
    margin: -50px 0 0 -50px
    border-radius: 50%
    opacity: 0.4
    background: #fff
    -webkit-animation: loaderAnim 0.7s linear infinite alternate forwards
    animation: loaderAnim 0.7s linear infinite alternate forwards

.blue::after
    background: var(--color-blue)
.pink::after
    background: var(--color-pink)
.purple::after
    background: var(--color-purple)
.green::after
    background: var(--color-green)

@keyframes loaderAnim
    to
        opacity: .7
        -webkit-transform: scale3d(0.5,0.5,1)
        -ms-transform: scale3d(0.5,0.5,1)
        transform: scale3d(0.5,0.5,1)