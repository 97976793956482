.header
    position: fixed
    top: 0
    left: 0
    width: 100%
    z-index: 11
    
    &__top
        background-color: #fff
        display: flex
        justify-content: space-between
        align-items: center
        padding: 10px 20px
        @media only screen and (min-width: 64rem)
            padding: 10px 40px

    &__logo
        max-width: 50%
        display: block
        @media only screen and (min-width: 64rem)
            max-width: 350px

        svg
            position: relative
            width: 100%
            height: auto
                    
    &__details
        position: relative

        @media only screen and (min-width: 32rem)
            margin-left: 50px
            max-width: 150px
            display: flex
            align-items: center

        &:before
            content: ''
            position: absolute
            top: 0
            left: -25px
            width: 1px
            height: 100%
            background-color: var(--color-pink)
            display: none
            @media only screen and (min-width: 32rem)
                display: block


        span
            color: var(--color-pink)
            font-size: 8px
            font-weight: 700
            line-height: 1.2
            @media only screen and (min-width: 32rem)
                font-size: 15px


    &__top-col
        display: flex
        &:first-child
            flex-direction: column
            @media only screen and (min-width: 32rem)
                flex-direction: row
                
        &:last-child
            align-items: center

    &__top-phone
        
        .phone-headline,
        .phone-desc
            font-size: 20px
            display: none
            @media only screen and (min-width: 64rem)
                display: inline-block
        
        .phone-headline
            font-weight: 700

        .phone-num
            padding: 4px 0 2px 0
            white-space: nowrap
            display: flex
            align-items: center
            
            width: 100%
            color: black
            text-decoration: none
            transition: color 0.2s 
                
            &:hover,
            &:focus-visible
                color: var(--color-pink)

            #phone
                width: 14px
                height: 18px

                margin-top: -2px
                @media only screen and (min-width: 64rem)
                    margin-left: -23px
                    margin-right: 7px

            span
                font-size: 20px
                display: none
                @media only screen and (min-width: 64rem)
                    display: inline-block

    &__bottom
        position: relative
    
    &__nav
        // height: 50vh
        overflow: auto
        left: 0
        top: 0
        width: 100%
        display: none
        padding-top: 30px
        padding-bottom: 100px
        position: absolute
        
        @media only screen and (min-width: 64rem)
            position: relative
            top: auto
            left: auto
            // height: 100%
            width: 100%
            display: flex
            justify-content: center
            padding-top: 30px
            padding-bottom: 0
            overflow: hidden
            -webkit-overflow-scrolling: touch

        &.is--visible
            display: flex

    &__list
        display: flex
        flex-direction: column
        list-style: none
        height: 100%
        padding: 20px
        @media only screen and (min-width: 64rem)
            flex-direction: row
            padding: 0

        
        li
            position: relative
            &:last-child
                &::after
                    @media only screen and (min-width: 64rem)
                        display: none

            &:after
                content: ''
                position: absolute
                top: 0
                right: 0
                width: 1px
                height: 100%
                background-color: #CACACA
                display: none
                @media only screen and (min-width: 64rem)
                    display: block

            a
                position: relative
                display: inline-block
                padding: var(--space-sm) 0
                color: #fff
                font-size: 20px
                font-weight: 400
                text-decoration: none
                @media only screen and (min-width: 64rem)
                    padding: var(--space-sm) var(--space-md)

                &:hover,
                &.active-nav-link
                    // font-weight: 700
                    &::after
                        opacity: 1

                &:after
                    content: ''
                    position: absolute
                    bottom: 0
                    left: 0
                    width: 100%
                    height: 5px
                    background-color: #CACACA
                    opacity: 0
                    transition: opacity 0.2s

.anim-menu-btn
    position: relative
    justify-content: center
    align-items: center
    height: 30px
    width: 30px
    color: #000
    background: transparent
    cursor: pointer
    display: block
    margin-left: 30px
    
    @media only screen and (min-width: 64rem)
        display: none
        
    @media only screen and (min-width: 64rem)
        height: 40px
        width: 40px

    &:focus-visible,
    &:hover
        &:before
            color: #000

        .anim-menu-btn__icon
            background-image: linear-gradient(#000, #000)
            
            &:before, 
            &:after
                background: #000
                
    &:before
        content: attr(data-content)
        position: absolute
        top: 50%
        left: calc( var(--space-md) * -1)
        transform: translate(-100%, -50%)
        text-decoration: none
        font-weight: 700
        font-family: 'Gotham'
        text-transform: uppercase
        font-size: 14px
        display: block

    &.anim-menu-btn--state-b
        &:before
            display: none

        &:focus-visible,
        &:hover
            .anim-menu-btn__icon
                background-image: linear-gradient(#000, #000)
                &:before, 
                &:after
                    background: #000

        .anim-menu-btn__icon--close
            background-size: 0% 100%
            &::before
                transform: translateY(0) rotate(45deg)
            &::after
                transform: translateY(0) rotate(-45deg)

    &__icon
        position: relative
        display: block
        font-size: 30px
        width: 1em
        height: 0.075em
        color: inherit
        background-image: linear-gradient(#000, #000)
        background-repeat: no-repeat
        transform: scale(1)

        @media only screen and (min-width: 64rem)
            font-size: 40px

        &:before, 
        &:after            
            content: ''
            position: absolute
            top: 0
            left: 0
            height: 100%
            width: 100%
            background: #000
            
    &__icon--close
        background-size: 100% 100%
        will-change: transform, background-size
        transition: transform .2s, background-size .2s

        .anim-menu-btn:active &
            transform: scale(0.9)

        &:before, 
        &:after
            will-change: inherit

        &:before
            transform: translateY(-0.2em) rotate(0)
        &:after
            transform: translateY(0.2em) rotate(0)
