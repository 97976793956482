.arrow-navigation
    position: fixed
    right: var(--space-md)
    bottom: var(--space-md)
    z-index: 10

    &__list
        position: relative
        list-style: none
        display: flex
        
        &:before
            content: attr(data-content)
            font-weight: 700
            font-size: 24px
            position: absolute
            top: 0
            right: 0
            left: auto
            transform: translate(0, -120%)
            @media only screen and (min-width: 32rem)
                top: 50%
                right: auto
                left: 0
                transform: translate(-120%, -50%)

        li
            line-height: 0

    &__link
        position: relative
        display: inline-block
        background-color: black
        padding: 10px
        margin-right: 5px

        &.next
            margin-right: 0
            margin-left: 5px
            .icon
                transform: rotate(180deg)
        
        .icon
            font-size: 20px
            color: #fff