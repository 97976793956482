:root {   
	/*******
	Generals
	********/ 

	--color-pink: #E30089;
	--color-blue: #1676D3;
	--color-brown: #BB5568;
	--color-purple: #B0059C;
	--color-purple-light: #A45AAE;
	--color-green: #00874B;
	--color-green-dark: #477E5C;;
	--color-turquoise: #367D8A;

	// Animation
	--ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
	--ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	--ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
	--ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);

	// Space
	--space-unit: 1rem;
	--space-2xs: calc(var(--space-unit)*0.5625);
	--space-xxs: 0.5625rem;
	--space-xs: 0.75rem;
	--space-sm: 1.125rem;
	--space-md: 2rem;
	--space-lg: calc(var(--space-unit)*3);
	--space-xl: calc(var(--space-unit)*4.875);
	--space-2xl: calc(var(--space-unit)*7.875);
	--space-3xl: calc(var(--space-unit)*12.75);
	--space-4xl: calc(var(--space-unit)*20.625);

	--radius: 0.375em;
	--radius-lg: calc(var(--radius, 0.25em)*2);
	--radius-md: var(--radius, 0.25em);

	// Max-Width
	--max-width-xxxxs: 20rem;
	--max-width-xxxs: 26rem;
	--max-width-xxs: 32rem;
	--max-width-xs: 38rem;
	--max-width-sm: 48rem;
	--max-width-md: 64rem;
	--max-width-lg: 80rem;
	--max-width-xl: 90rem;
	--max-width-xxl: 100rem;
	--max-width-xxxl: 120rem;
	--max-width-xxxxl: 150rem;

	--modal-transition-duration: 0.2s;

	--ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
	--ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	--ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
	--ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);


}

@font-face {
	font-family: "Kohinoor";
	src: url("./fonts/Kohinoor_Book.otf") format("opentype");
	font-style: normal;
	font-weight: 400;
}
@font-face {
	font-family: "Kohinoor";
	src: url("./fonts/Kohinoor_Bold.otf") format("opentype");
	font-style: normal;
	font-weight: 700;
}

*, *::after, *::before {
	box-sizing: inherit;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video, hr {
	margin: 0;
	padding: 0;
	border: 0;
}

html {
	box-sizing: border-box;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, main, form legend {
	display: block;
}

body{
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	font-family: "Kohinoor";
}

button {
	background-color: transparent;
	padding: 0;
	border: 0;
	border-radius: 0;
	color: inherit;
	line-height: inherit;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	cursor: pointer;
}
